/**
 * Same as useEffect but only runs on first mount
 *
 * Usage:
 *
 * const isFirstRender = useEffectOnlyRunOnFirstMount();
 *
 * useEffect(() => {
 *   if (isFirstRender) {
 *     // Do something only on first render
 *   }
 * }, []);
 */

import { useEffect, useRef } from 'react';

const useEffectOnlyRunOnFirstMount = (): React.ReactNode => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
};

export { useEffectOnlyRunOnFirstMount };
