import * as Accordion from '@radix-ui/react-accordion';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { motion } from 'framer-motion';
import { isEmpty, kebabCase } from 'lodash';
import { ChevronDown } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

import { Link } from '@/components/link';
import { HeaderNavItems } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';

import { NavCards } from '../cards';
import { NavLinks } from '../links';
import { NavLinksMini } from '../links-mini';

interface NavMobileProps {
  headerNav: HeaderNavItems[];
  navIsOpen: boolean;
}

const accordionContentInnerVariants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0.5, y: -5 },
};

const NavMobile = ({ headerNav, navIsOpen }: NavMobileProps) => {
  const pathname = usePathname();
  const [openItems, setOpenItems] = useState<string[]>([]);
  const {
    base,
    accordionItem,
    accordionTrigger,
    accordionContent,
    accordionContentInner,
    navLink,
    accordionTriggerBg,
    chevronDown,
  } = styles({
    navIsOpen,
  });

  return (
    <Accordion.Root type="multiple" className={base()} onValueChange={setOpenItems}>
      {headerNav.map((item) => {
        if (item.isHidden) return null;

        if (!isEmpty(item.links) || !isEmpty(item.linksMini) || item.cards) {
          return (
            <Accordion.Item key={`${kebabCase(item.label)}-mobile-nav`} value={item.label} className={accordionItem()}>
              <Accordion.Trigger className={accordionTrigger()}>
                <span className="relative z-2">{item.label}</span>
                <ChevronDown className={chevronDown()} />
                <span className={accordionTriggerBg()} />
              </Accordion.Trigger>
              <Accordion.Content className={accordionContent()}>
                <motion.div
                  className={accordionContentInner()}
                  initial="closed"
                  variants={accordionContentInnerVariants}
                  animate={openItems.includes(item.label) ? 'open' : 'closed'}
                >
                  {item.cards && <NavCards cards={item.cards} pathname={pathname} />}
                  {(item.links || item.linksMini) && (
                    <div className="grid w-full items-start gap-1 divide-x divide-grey-300 md:grid-cols-2">
                      {item.links && <NavLinks items={item.links} pathname={pathname} className={item.className} />}
                      {item.linksMini && <NavLinksMini items={item.linksMini} pathname={pathname} />}
                    </div>
                  )}
                </motion.div>
              </Accordion.Content>
            </Accordion.Item>
          );
        }

        return (
          <NavigationMenu.Item key={`${kebabCase(item.label)}-nav`} asChild>
            <div>
              <NavigationMenu.Link active={pathname === item.url} asChild className={navLink()}>
                <Link href={item.url}>{item.label}</Link>
              </NavigationMenu.Link>
            </div>
          </NavigationMenu.Item>
        );
      })}
    </Accordion.Root>
  );
};

const styles = tv({
  slots: {
    base: 'relative left-0 top-0 mb-0 flex w-full flex-col justify-between bg-grey-100 px-2 py-0 max-md:opacity-0 max-md:transition-opacity max-md:delay-0 max-md:ease-in-out md:hidden',
    accordionItem:
      'relative -mb-px after:absolute after:bottom-0 after:left-0.5 after:right-0.5 after:h-px after:bg-grey-300',
    accordionTrigger:
      'relative flex w-full items-center justify-between rounded p-3.5 font-medium text-blue-700 transition-all [&_.chevron-down]:data-[state=open]:rotate-180 [&_.trigger-bg]:data-[state=open]:scale-100 [&_.trigger-bg]:data-[state=open]:opacity-100',
    accordionContent:
      'overflow-hidden py-2 transition-all ease-in-out data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down data-[state=open]:opacity-100',
    accordionContentInner: '',
    navLink: 'block w-full items-center p-3.5 font-medium leading-ultraTight text-blue-700',
    accordionTriggerBg:
      'trigger-bg pointer-events-none absolute inset-0 block scale-[.95] rounded border border-grey-300 bg-white opacity-0 transition-all duration-100 ease-in-out',
    chevronDown: 'chevron-down relative z-2 h-4 w-4 transition-transform',
  },
  variants: {
    navIsOpen: {
      true: {
        base: 'max-md:opacity-1 max-md:delay-300',
      },
      false: null,
    },
  },
  compoundVariants: [
    {
      navIsOpen: false,
      variant: 'light',
      class: 'max-md:[&_.logo-link_svg_path]:fill-white',
    },
  ],
});

export { NavMobile };
