import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { kebabCase } from 'lodash';
import NextLink from 'next/link';

import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { Text } from '@/components/text';
import { HeaderNavItems } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';

interface NavCardsProps {
  cards: HeaderNavItems['cards'];
  pathname: string;
}

export const NavCards = ({ cards, pathname }: NavCardsProps) => {
  const { cardLink, base, image, heading, description, cardLinkBg, listItem, listHeading, list } = styles();

  return (
    <div className={base()}>
      {cards?.map((cardGroup) => (
        <div key={`${kebabCase(cardGroup.heading || 'group')}-card-section`}>
          {cardGroup.heading && (
            <Heading variant="h6" as="h2" className={listHeading()}>
              {cardGroup.heading}
            </Heading>
          )}
          <ul className={list()}>
            {cardGroup.items?.map((card) => (
              <NavigationMenu.Item key={`${kebabCase(card.heading)}-card-nav`} className={listItem()}>
                <NavigationMenu.Link asChild>
                  <NextLink
                    href={card.url}
                    className={cardLink({ subLinkIsActive: pathname === card.url })}
                    data-cy="marketing-nav-subnav-link"
                  >
                    <Image src={card.image} alt={card.heading} className={image()} />
                    <div>
                      <Heading variant="h3" as="h3" className={heading()}>
                        {card.heading}
                      </Heading>
                      {card.description && (
                        <Text variant="secondary" className={description()}>
                          {card.description}
                        </Text>
                      )}
                    </div>
                    <span className={cardLinkBg()} />
                  </NextLink>
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'grid gap-2 p-2 max-md:pb-3 lg:w-[400px]',
    list: 'grid',
    listHeading: 'mb-1 px-2 text-sm font-semibold text-grey-500',
    listItem: 'max-md:pt-2 first:max-md:pt-0',
    inner: 'grid grid-cols-[108px_1fr] items-center gap-3',
    imageWrapper: 'flex items-center',
    cardLink:
      'text-decoration-none group relative grid grid-cols-[108px_1fr] items-center gap-3 p-2 [&_*]:relative [&_*]:z-2',
    image: 'w-full rounded [&_img]:mb-0',
    heading: 'mb-0.5 text-baseSm font-semibold leading-none text-blue-500',
    description: 'mb-0 text-grey-500 transition-colors group-hover:text-blue-500',
    cardLinkBg:
      '!absolute bottom-0 left-0 right-0 top-0 !z-1 block scale-[.95] rounded bg-grey-100 opacity-0 transition-all duration-100 ease-in-out group-hover:scale-100 group-hover:opacity-100',
  },
  variants: {
    subLinkIsActive: {
      true: {
        cardLink: 'font-bold',
      },
    },
  },
});
