import { startsWith } from 'lodash';
import { Image } from 'react-datocms';

import { Block } from '@/components/block';
import { HeaderBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const HeaderBlock = ({
  id,
  heading,
  body,
  blockAlignment,
  buttons = [],
  children = null,
  image,
  variant = 'light',
  headingElement,
  preHeading,
  className,
  ...props
}: HeaderBlockProps) => {
  const { base, blockWrapper, block, backgroundImage } = headerStyles({ variant });

  return (
    <div id="header-block" className={base({ className })} {...props}>
      <div className={blockWrapper()}>
        <Block
          id={id}
          heading={heading}
          headingVariant={startsWith(variant, 'hero') ? 'd1' : 'd2'}
          headingElement={headingElement}
          body={body}
          buttons={buttons}
          blockAlignment={blockAlignment}
          contentColumns={{
            left: 'w-full md:w-9/12 lg:w-10/12',
            center: 'w-full md:w-9/12 lg:w-10/12',
            right: 'w-full md:w-9/12 lg:w-10/12',
          }}
          className={block()}
        >
          {children}
        </Block>
      </div>

      {image?.responsiveImage && (
        <div className={backgroundImage()}>
          <Image data={image?.responsiveImage} layout="fill" />
        </div>
      )}
    </div>
  );
};

const headerStyles = tv({
  slots: {
    base: 'relative first:pt-[var(--page-header-height)] [&_.block-rich-text_p]:text-baseLg',
    block: 'pb-8 pt-10 lg:pb-12 lg:pt-12 xl:pb-12 xl:pt-12',
    blockWrapper: 'relative z-2',
    backgroundImage: 'absolute bottom-0 left-1/2 top-0 z-1 w-full max-w-[1800px] -translate-x-1/2 [&_img]:object-cover',
  },
  variants: {
    variant: {
      light: 'bg-transparent text-text-primary [&_p]:text-text-primary',
      dark: 'bg-brandDarkBlue text-white [&_.block-rich-text]:opacity-80 [&_article]:text-white [&_p]:text-white',
      hero: 'grid h-screen max-h-[850px] min-h-[650px] items-center bg-lightBlue-50 text-text-primary [&_h1]:-mt-12 [&_h1]:mb-sm [&_h2]:-mt-12 [&_h2]:mb-sm [&_p]:font-medium [&_p]:text-text-primary',
      heroDark:
        'grid h-screen max-h-[850px] min-h-[650px] items-center bg-brandDarkBlue !text-white [&_h1]:-mt-12 [&_h1]:mb-sm [&_h1]:text-white [&_h2]:-mt-12 [&_h2]:mb-sm [&_p]:font-medium [&_p]:text-white',
      route: 'bg-lightBlue-50 pb-12 text-text-primary',
      sm: '[&_.block-wrapper]:pb-6 [&_.block-wrapper]:pt-6 [&_.block-wrapper]:lg:pb-lg [&_.block-wrapper]:lg:pt-xl [&_.block-wrapper]:xl:pb-12 [&_.block-wrapper]:xl:pt-16 [&_h1]:text-lg [&_h1]:lg:text-xl [&_h1]:xl:text-[60px] [&_h2]:text-lg [&_h2]:lg:text-xl [&_h2]:xl:text-[60px]',
    },
  },
  compoundVariants: [
    {
      variant: ['hero', 'heroDark'],
      class:
        '[&_.block-rich-text]:max-w-[400px] [&_.block-rich-text]:lg:max-w-[500px] [&_.block-rich-text_p]:leading-snug [&_.block-rich-text_p]:lg:text-md',
    },
  ],
});

export { HeaderBlock };
