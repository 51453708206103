import { render } from 'datocms-structured-text-to-plain-text';
import { compact, isEmpty, unescape } from 'lodash';

import { SITE_DOMAIN, SITE_NAME } from '@/lib/constants';
import { FaqInterface } from '@/types';
import { RichTextBlockProps } from '@/types/block-types';
import { AllPostsPostInterface, PostInterface } from '@/types/cms-types';

import { getPostUrl } from '../urls';

/**
 * Get FAQ JSONLD Schema
 * @param questions FaqInterface[]
 * @returns structured ld+json data object
 */
export const getFaqSchema = (questions: FaqInterface[] = []) => {
  const mainEntity = questions.map((question) => {
    if (question) {
      return {
        '@type': 'Question',
        inLanguage: 'en',
        name: question.heading,
        acceptedAnswer: {
          '@type': 'Answer',
          text: question.body,
        },
      };
    }
    return null;
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: compact(mainEntity),
  };
};

/**
 * Get Company JSONLD Schema
 * @returns structured ld+json data object
 */
export const getOrganisationSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Fluent Cargo',
    legalName: 'Fluent Cargo',
    url: 'https://www.fluentcargo.com',
    logo: 'https://www.fluentcargo.com/apple-touch-icon.png',
    foundingDate: '2020',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '10-20 Gwynne St',
      addressLocality: 'Cremorne',
      addressRegion: 'Victoria',
      postalCode: '3121',
      addressCountry: 'Australia',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      email: 'contact@fluentcargo.com',
    },
    sameAs: ['https://www.linkedin.com/company/fluentcargo/'],
  };
};

/**
 * Get post JSONLD Schema
 * @returns structured ld+json data object
 */
export const getPostSchema = (post: PostInterface) => {
  // @TODO: Should we expand this to include other block types?
  const richTextBlocks = post.blocks?.filter((block) => block.__typename === 'RichTextBlockRecord');

  if (!post || !richTextBlocks?.length) {
    return null;
  }

  const plainTextSummary = unescape(post.summary?.replace(/<[^>]+>/g, ''));

  // Account for multiple rich text blocks
  const plainTextBodies = richTextBlocks.map((block) => {
    const heading = block.heading ? `${block.heading} ` : '';
    const body = render(block.body as RichTextBlockProps['body']);
    return `${heading}${body}`;
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${SITE_DOMAIN}/${getPostUrl(post)}`,
    },
    headline: post.title,
    image: [post.image?.responsiveImage.src],
    datePublished: post.publishedAt,
    dateModified: post.updatedAt,
    author: {
      '@type': 'Person',
      name: post.author.name,
    },
    publisher: {
      '@type': 'Organization',
      name: SITE_NAME,
      logo: {
        '@type': 'ImageObject',
        url: `${SITE_DOMAIN}/assets/fluent-logo-jsonld.png`,
        width: 320,
        height: 260,
      },
    },
    description: plainTextSummary,
    articleSection: post.postType,
    articleBody: plainTextBodies.join('\n'),
  };
};

/**
 * Get post list JSONLD Schema
 * @returns structured ld+json data object
 */
export const getPostsSchema = (posts: AllPostsPostInterface[]) => {
  if (isEmpty(posts)) {
    return null;
  }

  const itemListElement = posts.map((post, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    url: `${SITE_DOMAIN}/${getPostUrl(post)}`,
    name: post.title,
    abstract: post.summary,
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement,
  };
};
