// Used for all Static Pages
import Head from 'next/head';
import Script from 'next/script';
import { ReactNode, useEffect } from 'react';
import { renderMetaTags } from 'react-datocms';
import { useResetRecoilState } from 'recoil';

import { DatoPreviewStatus } from '@/components/dato-preview-status';
import { Main } from '@/components/main';
import { PageFooter } from '@/components/page-footer';
import { PageHeader } from '@/components/page-header';
import { useEffectOnlyRunOnFirstMount } from '@/hooks/use-effect-only-on-first-mount';
import { ADS_PUB_ID } from '@/lib/constants';
import { viewIdsState } from '@/state/routes';
import { SeoMetaTagType } from '@/types';
import { DatoPreviewStatusInterface, PostSnippets } from '@/types/cms-types';
import { getOrganisationSchema } from '@/utils/helpers/json-ld';

type Props = {
  children?: ReactNode;
  headerVariant?: 'light' | 'dark';
  metaTags?: SeoMetaTagType[];
  previewStatus?: DatoPreviewStatusInterface;
  canonical?: string | null;
  posts?: PostSnippets;
};

const PageLayout = ({ children, headerVariant, metaTags = [], canonical, previewStatus, posts }: Props) => {
  const resetViewIds = useResetRecoilState(viewIdsState);
  const isFirstRender = useEffectOnlyRunOnFirstMount();
  const organisationJsonLd = getOrganisationSchema();

  useEffect(() => {
    // Reset View Ids when not on Route Search page to stop drawers breaking/erroring when left open
    // @TODO maybe there is a better way to manage this?
    resetViewIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link type="application/xml" title="Sitemap" href="/sitemap-index.xml" />
        {canonical && <link rel="canonical" href={canonical} />}
        {metaTags && renderMetaTags(metaTags)}
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(organisationJsonLd)}`,
          }}
        />
      </Head>
      <Script
        async
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${ADS_PUB_ID}`}
        crossOrigin="anonymous"
      />

      {previewStatus && <DatoPreviewStatus status={previewStatus.status} error={previewStatus.error} />}

      <Main>
        <PageHeader variant={headerVariant} posts={posts} />
        {children}
        <PageFooter />
      </Main>
    </>
  );
};

export { PageLayout };
