import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { kebabCase } from 'lodash';
import NextLink from 'next/link';

import { Heading } from '@/components/heading';
import { DynamicIcon } from '@/components/ui/dynamic-icon';
import { HeaderNavItems } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';

interface NavLinksMiniProps {
  items: HeaderNavItems['linksMini'];
  pathname: string;
}

export const NavLinksMini = ({ items, pathname }: NavLinksMiniProps) => {
  const { subLinkMini, subLinkBg, base, heading } = styles();

  return (
    <div className={base()}>
      {items &&
        items.map((linkList, index) => (
          <div key={index}>
            <Heading variant="h6" as="h3" className={heading()}>
              {linkList.heading}
            </Heading>
            <ul key={index}>
              {linkList.links.map((link) => (
                <NavigationMenu.Item key={`${kebabCase(link.label)}-link-nav`}>
                  <NavigationMenu.Link asChild>
                    <NextLink
                      href={link.url}
                      className={subLinkMini({ subLinkIsActive: pathname === link.url })}
                      data-cy="marketing-nav-subnav-link"
                    >
                      <DynamicIcon size={16} name={link.icon} />

                      <span className="leading-none">{link.label}</span>
                      <span className={subLinkBg({ subLinkIsActive: pathname === link.url })} />
                    </NextLink>
                  </NavigationMenu.Link>
                </NavigationMenu.Item>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex flex-col gap-4 px-1.5 pb-3 pt-4 md:px-3.5 md:py-4 md:pr-0 md:pt-5',
    heading: 'mb-2 ml-2 font-semibold leading-none text-grey-500',
    subLinkMini:
      'group relative flex items-center gap-2 p-2 text-baseSm font-semibold text-brandDarkBlue transition-colors [&_*]:relative [&_*]:z-2',
    subLinkBg:
      '!absolute bottom-0 left-0 right-0 top-0 !z-1 block scale-[.95] rounded bg-grey-100 opacity-0 transition-all duration-100 ease-in-out group-hover:scale-100 group-hover:opacity-100',
  },
  variants: {
    subLinkIsActive: {
      true: {
        subLinkMini: 'font-bold',
      },
    },
  },
});
