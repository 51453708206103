import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { kebabCase } from 'lodash';
import NextLink from 'next/link';

import { Heading } from '@/components/heading';
import { Text } from '@/components/text';
import { DynamicIcon } from '@/components/ui/dynamic-icon';
import { HeaderNavItems } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';

interface NavLinksProps {
  items: HeaderNavItems['links'];
  pathname: string;
  className?: string;
}

export const NavLinks = ({ items, pathname, className }: NavLinksProps) => {
  const { base, subLink, subLinkIcon, subLinkIconBg, subLinkHeading, subLinkDescription, subLinkBg } = styles();

  return (
    items && (
      <div className={base({ className })}>
        <ul>
          {items.map((link) => {
            if (link.isHidden) return null;

            const subLinkIsActive = pathname === link.url;
            return (
              <NavigationMenu.Item key={`${kebabCase(link.label)}-link-nav`}>
                <NavigationMenu.Link asChild>
                  <NextLink
                    href={link.url}
                    className={subLink({ subLinkIsActive })}
                    data-cy="marketing-nav-subnav-link"
                  >
                    {link.icon && (
                      <div className={subLinkIcon()}>
                        <DynamicIcon name={link.icon} />
                        <span className={subLinkIconBg()} />
                      </div>
                    )}
                    <div>
                      <Heading variant="h5" as="h3" className={subLinkHeading()}>
                        {link.label}
                      </Heading>
                      {link.description && (
                        <Text variant="secondary" className={subLinkDescription()}>
                          {link.description}
                        </Text>
                      )}
                    </div>
                    <span className={subLinkBg({ subLinkIsActive })} />
                  </NextLink>
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            );
          })}
        </ul>
      </div>
    )
  );
};

const styles = tv({
  slots: {
    base: 'mb-3 mt-1 grid h-auto max-md:ml-1 md:m-0 md:gap-px md:p-2',
    subLink: 'text-decoration-none group relative flex gap-2.5 rounded p-2 [&_*]:relative [&_*]:z-2',
    subLinkIcon: 'relative flex size-10 shrink-0 items-center justify-center text-blue-500',
    subLinkIconBg:
      '!absolute bottom-0 left-0 right-0 top-0 !z-1 block rounded border border-solid border-grey-300 bg-white transition-all duration-100 ease-in-out group-hover:scale-110',
    subLinkHeading: 'mb-0 text-blue-500',
    subLinkDescription: 'm-0 text-grey-500 transition-colors group-hover:text-blue-500',
    subLinkBg:
      '!absolute bottom-0 left-0 right-0 top-0 !z-1 block scale-[.95] rounded bg-grey-100 opacity-0 transition-all duration-100 ease-in-out group-hover:scale-100 group-hover:opacity-100',
  },
  variants: {
    subLinkIsActive: {
      true: {
        subLink: 'max-md:[&_span:first-of-type]:underline',
        subLinkBg: 'scale-100 opacity-100',
      },
    },
  },
});
