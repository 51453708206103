/**
 * Dynamically loads an icon from lucide-react based on the icon name
 */

import { AnimatePresence, motion } from 'framer-motion';
import { LucideProps } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import dynamic from 'next/dynamic';

export type IconName = keyof typeof dynamicIconImports;

export interface DynamicIconProps extends LucideProps {
  name: IconName;
}

const DynamicIcon = ({ name, ...props }: DynamicIconProps) => {
  if (!dynamicIconImports[name]) {
    console.error(`Icon "${name}" doesn't exist in lucide-react`);
  }

  const LucideIcon = dynamic(dynamicIconImports[name]);

  return (
    <AnimatePresence>
      <motion.div className="flex" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <LucideIcon {...props} />
      </motion.div>
    </AnimatePresence>
  );
};

export { DynamicIcon };
