import { Heading } from '@/components/heading';
import { Link } from '@/components/link';
import { Text } from '@/components/text';
import { AllPostsPostPartial } from '@/types/cms-types';
import { formatDateForUser } from '@/utils/format/date-time';
import { truncateWords } from '@/utils/strings';
import { tv } from '@/utils/styles';

interface NavPostsProps {
  posts: AllPostsPostPartial[];
  header?: string;
}

export const NavPosts = ({ posts, header }: NavPostsProps) => {
  const { base, headerStyle, postList, postLink, dateText, postTitle, postLinkBg } = styles();

  return (
    <div className={base()}>
      {Boolean(header) && (
        <Heading variant="h6" as="h3" className={headerStyle()}>
          {header}
        </Heading>
      )}
      <ol className={postList()}>
        {posts.map((post) => {
          const { title, publishedAt, slug } = post;
          return (
            <li key={title}>
              <Link href={`/blog/${slug}`} className={postLink()}>
                <Text variant="label" className={dateText()}>
                  {formatDateForUser(new Date(publishedAt).toISOString())}
                </Text>
                <Heading variant="h5" className={postTitle()}>
                  {truncateWords(title, 11)}
                </Heading>
                <span className={postLinkBg()} />
              </Link>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'h-full p-4 pt-5 md:pl-0',
    headerStyle: 'mb-2 font-semibold leading-none text-grey-500',
    postList: '-ml-2',
    postLink: 'group relative block p-2 text-blue-500',
    dateText: 'relative z-2 mb-0 text-sm text-grey-500 transition-colors group-hover:text-blue-500',
    postTitle: 'relative z-2 mb-1 font-semibold',
    postLinkBg:
      '!absolute bottom-0 left-0 right-0 top-0 z-1 block scale-[.95] rounded bg-grey-100 opacity-0 transition-all duration-100 ease-in-out group-hover:scale-100 group-hover:opacity-100',
  },
});
