import { isEmpty, startCase, words } from 'lodash';
import pluralize from 'pluralize';

import { DELAY_STATUS, MODE_WATER, POST_TYPES, QUICKEST_ROUTE } from '@/lib/constants';
import { LocationDictionaryModel } from '@/lib/models/location/types';
import { OptionModel } from '@/lib/models/option/types';
import { RouteSummaryModel } from '@/lib/models/route-summary/types';
import { RouteSummaryOptionModel } from '@/lib/models/route-summary-option/types';
import { getSegmentTransferNames } from '@/lib/models/segment/utils/get-segment-transfer-names';
import { LocationInterface, RouteScheduleInterface, VehicleDelayInterface } from '@/types';
import { PlaceInterface, SegmentModeType } from '@/types/api-types';
import { PostInterface } from '@/types/cms-types';
import { formatDistance, formatDuration, formatFrequency, formatPortName } from '@/utils/format';
import { convertModeToVehicleVerbs, convertModeType } from '@/utils/format/mode';
import { joinByCommaAnd } from '@/utils/helpers';
import { getPlaceAndCountryString } from '@/utils/places';
import { getRouteTransferNames } from '@/utils/route';

/**
 * Generates a Route page title
 * @param from - Departure Location
 * @param to - Arrival Location
 * @returns A to B by Air freight, Cargo ship or Road
 */
export const getRoutePageMetaTitle = (from: LocationInterface, to: LocationInterface) => {
  return `${from.name} to ${to.name} by Air freight, Cargo ship or Road`;
};

/**
 * Generates a Route page description
 * @param from - Departure Location
 * @param to - Arrival Location
 * @returns Compare freight rates, transit times and transport modes to find the best way to ship your freight and cargo from X, Australia to Y, Germany.`
 */
export const getRoutePageMetaDescription = (from: LocationInterface, to: LocationInterface) => {
  return `Compare freight rates, transit times and emissions to find the best way to ship your freight and cargo from ${getPlaceAndCountryString(
    from,
  )} to ${getPlaceAndCountryString(to)}.`;
};

/**
 * Get Route Mode Description (used on SEO Pages)
 * @param routes Array of Mode Routes
 * @param from Origin Place
 * @param to Destination Place
 * @returns String description of route
 */
export const getModeDescriptionString = (
  routes: RouteSummaryModel[] | null,
  mode: SegmentModeType,
  from: PlaceInterface,
  to: PlaceInterface,
) => {
  if (!routes?.length) return '';

  const quickestRoute = routes.find((route) => route.label === QUICKEST_ROUTE);
  if (!quickestRoute) return '';

  const quickestRouteString = getQuickestRouteString(quickestRoute, from, to);
  const frequencyStr = getFrequencyDescriptionString(quickestRoute.routeSummary.frequency, mode);
  const carrierDescription = getSingleCarrierRouteDescriptionString(quickestRoute.routeOptions?.[0], mode);
  return `${quickestRouteString} ${frequencyStr} ${carrierDescription}`;
};

/**
 * Road Mode Description
 * @param routes Array of Mode Routes
 * @param from Origin Place
 * @param to Destination Place
 * @returns String description of road route
 */
export const getRoadDescriptionString = (
  routes: RouteSummaryModel[],
  from: PlaceInterface,
  to: PlaceInterface,
): string => {
  if (isEmpty(routes)) return '';
  const { durationSeconds, distanceKm } = routes[0].routeSummary;
  const durationStr = formatDuration(durationSeconds);
  const distanceStr = formatDistance(distanceKm);
  return `It is also possible to transport goods by road from ${from.name} to ${to.name}. The total distance is around ${distanceStr} and will usually takes around ${durationStr} by road. Note: This time estimate is based on typical traffic conditions and does not take into consideration delays or congestion.`;
};

/**
 * Returns string describing fastest route
 * @param routes Array of Mode Routes
 * @param from Origin Place
 * @param to Destination Place
 * @returns String
 */
export const getQuickestRouteString = (route: RouteSummaryModel, from: PlaceInterface, to: PlaceInterface): string => {
  const { destination, origin, routeSummary } = route;
  const { durationSeconds, routeType } = routeSummary;
  const durationStr = formatDuration(durationSeconds);
  const originPortString = formatPortName(origin);
  const destinationPortString = formatPortName(destination);
  const vehicle = convertModeType(routeType);

  return `The quickest way to get from ${from.name} to ${to.name} by ${vehicle} will take about ${durationStr} and departs from ${originPortString} and arrives into ${destinationPortString}.`;
};

/**
 * Returns a string regarding departure frequency
 * @param frequency - departures per hour
 * @param mode - Vehicle mode
 * @returns There are {flights|ships} departing {every day} on this route.`
 */
export const getFrequencyDescriptionString = (frequency: number, mode: SegmentModeType): string => {
  const carrierVehicle = convertModeToVehicleVerbs(mode);
  const frequencyStr = formatFrequency(frequency).toLocaleLowerCase();
  return `There are ${carrierVehicle} departing ${frequencyStr} on this route.`;
};

/**
 * Returns a string describing a carriers route
 * @param carrier Simple Route Carrier Interface
 * @returns String
 */
export const getSingleCarrierRouteDescriptionString = (
  option?: RouteSummaryOptionModel,
  mode?: SegmentModeType,
): string => {
  if (!option || !option.carrierName) return '';
  const { carrierName } = option;
  const carrierVehicle = mode ? convertModeToVehicleVerbs(mode) : '';
  const frequency = formatFrequency(option.frequency).toLocaleLowerCase();
  return `${carrierName} is one of the carriers that operates regular services on this route with ${carrierVehicle} departing ${frequency}.`;
};

const getModeStopString = (mode: SegmentModeType): string => (mode === MODE_WATER ? 'port call' : 'stop');

const getTransfersString = ({ transfers }: { transfers: string[] }) => {
  const firstThree = transfers.slice(0, 2);
  const remaining = transfers.slice(3, transfers.length);
  return `Transfer at ${joinByCommaAnd(firstThree)}${remaining.length ? ` + ${remaining.length} others` : ''}`;
};

const getTransferLocationsString = ({ transfers }: { transfers: string[] }) => {
  return `Via ${transfers.join(', ')}`;
};

const getTransfersShortString = ({ transferCount }: { transferCount: number }): string =>
  `${transferCount} ${pluralize('transfer', transferCount)}`;

const getStopString = ({
  stopCount,
  mode,
  hasMultipleCarriers = false,
  shouldIncludeStopDetail = true,
}: {
  stopCount: number;
  mode: SegmentModeType;
  hasMultipleCarriers?: boolean;
  shouldIncludeStopDetail?: boolean;
}) => {
  if (!shouldIncludeStopDetail) {
    return 'Direct';
  }

  const prefix = hasMultipleCarriers && stopCount ? 'Up to ' : '';
  const modeString = getModeStopString(mode);
  return `Direct (${prefix}${stopCount ? `${stopCount} ${pluralize(modeString, stopCount)}` : `no stops`})`;
};

export const getScheduleOptionStopsString = (option: RouteScheduleInterface): string => {
  const { attributes, segments, mode } = option;
  const { stopCount } = attributes;
  const transfers = getRouteTransferNames(segments);

  if (!transfers.length) {
    return getStopString({ stopCount, mode });
  }

  return getTransfersString({ transfers });
};

export const getScheduleOptionStopsShortString = (option: RouteScheduleInterface): string => {
  const { attributes, mode, segments } = option;
  const { stopCount, transferCount } = attributes;
  const transfers = getRouteTransferNames(segments, true);

  if (transferCount === 0) {
    return getStopString({ stopCount, mode, shouldIncludeStopDetail: stopCount !== 0 });
  }

  return getTransferLocationsString({ transfers });
};

export const getOptionModelStopsShortString = ({
  option,
  mode,
}: {
  option: OptionModel;
  mode: SegmentModeType;
}): string => {
  const { numTransfers, numStopsRange } = option;

  if (numTransfers === 0) {
    return getStopString({ stopCount: numStopsRange[1], mode, shouldIncludeStopDetail: false });
  }

  return getTransfersShortString({ transferCount: numTransfers });
};

export const getOptionModelStopsListString = ({
  option,
  locations,
  mode,
}: {
  option: OptionModel;
  locations: LocationDictionaryModel;
  mode: SegmentModeType;
}): string => {
  const { numStopsRange, segments } = option;
  const stopCount = numStopsRange[1];

  const transfers = getSegmentTransferNames({ segments, codesOnly: true, locations });

  if (!transfers.length) {
    return stopCount === 0 ? 'Non stop' : `${stopCount} ${pluralize(getModeStopString(mode), stopCount)}`;
  }

  return transfers.join(', ');
};

/**
 * Transforms Post Type to a human readable string
 * @param postType
 */
export const transformPostType = (postType: PostInterface['postType']): string => {
  switch (postType) {
    case POST_TYPES.BLOG:
      return 'Article';
    case POST_TYPES.PRESS:
      return 'Press release';
    case POST_TYPES.CASE_STUDIES:
      return 'Case study';
    default:
      return startCase(postType);
  }
};

/**
 * Formats a vehicle delay status into a human friendly string
 */
export const formatDelayStatusLabel = (delayStatus: VehicleDelayInterface | undefined) => {
  if (!delayStatus?.status) return 'N/A';

  switch (delayStatus.status) {
    case DELAY_STATUS.AHEAD:
      return 'Ahead of schedule';
    case DELAY_STATUS.ONTIME:
      return 'Appears to be on time';
    case DELAY_STATUS.DELAYED:
      return `Potentially delayed (${delayStatus.days} days)`;
    default:
      return delayStatus.status;
  }
};

/**
 * Pads a number with leading zeros
 */
export const zeroPad = (num: number | string, places: number) => String(num).padStart(places, '0');

/**
 * Truncates a string to a certain number of words
 */
export const truncateWords = (str: string, numWords: number): string => {
  // Split the string into an array of words
  const wordArray = words(str);
  // Get the first `numWords` words from the array
  const truncatedArray = wordArray.slice(0, numWords);
  // Join the truncated array of words back into a string, separated by a space character
  const truncatedString = truncatedArray.join(' ');

  // Append an ellipsis to the truncated string if the original string was truncated
  return truncatedArray.length < wordArray.length ? `${truncatedString}...` : truncatedString;
};

/**
 * Returns a string descripting the percent range of emissions
 */
export const getEmissionsLevelString = (percent: number) => {
  if (percent > 95) {
    return 'Highest';
  }
  if (percent > 75) {
    return 'High';
  }
  if (percent > 55) {
    return 'Above avg.';
  }
  if (percent < 5) {
    return 'Lowest';
  }
  if (percent < 25) {
    return 'Low';
  }
  if (percent < 45) {
    return 'Below avg.';
  }
  return 'Average';
};

/**
 * Formats a carrier ID and Number into a consistent string
 */
export const formatFlightNumber = (carrierCode?: string, vehicleNumber?: string) => {
  if (carrierCode && vehicleNumber) {
    return `${carrierCode} ${vehicleNumber}`;
  }

  return '';
};
