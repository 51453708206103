import { Button } from '@/components/button';
import { Container } from '@/components/container';
import { Text } from '@/components/text';
import { DatoPreviewStatusInterface } from '@/types/cms-types';
import { tv } from '@/utils/styles';

export const statusMessage = {
  connecting: 'Connecting to DatoCMS...',
  connected: 'Connected to DatoCMS, receiving live updates',
  closed: 'Connection closed', // This will never be shown
};

const DatoPreviewStatus = ({ status, error, ...props }: DatoPreviewStatusInterface) => {
  if ((!status && !error) || (status === 'closed' && !error)) {
    return null;
  }

  const { base } = styles({ variant: error ? 'error' : status });

  return (
    <aside className={base()} {...props}>
      <Container>
        <div className="flex items-center">
          <div>
            {status && (
              <Text variant="secondary" className="m-0 leading-normal">
                {statusMessage[status]}
              </Text>
            )}
            {error && (
              <div>
                <Text variant="secondary" className="leading-normal">
                  Error: {error.code}, {error.message}
                </Text>
                {error.response && <pre>{JSON.stringify(error.response, null, 2)}</pre>}
              </div>
            )}
          </div>

          <div className="ml-auto flex justify-end">
            <Button variant="secondaryLight" href="/api/exit-preview" size="small">
              Exit Preview Mode
            </Button>
          </div>
        </div>
      </Container>
    </aside>
  );
};

const styles = tv({
  slots: {
    base: 'fixed bottom-0 left-0 z-10 flex w-full px-0 py-4 transition-colors duration-200 ease-in-out md:relative md:bottom-auto md:left-auto md:z-auto [&_p]:mb-0',
  },
  variants: {
    variant: {
      error: {
        base: 'border-red-500 bg-red-100 text-red-700',
      },
      connected: {
        base: 'border-green-400 bg-green-50 text-green-600 [&_p]:font-bold',
      },
      connecting: {
        base: 'bg-grey-800 text-white',
      },
      closed: {},
    },
  },
});

export { DatoPreviewStatus };
