import { CANAL_TRANSIT, TRANSFER_STOP } from '@/lib/constants';
import { LocationDictionaryModel } from '@/lib/models/location/types';
import { OptionModelSequence, SegmentTransferModel } from '@/lib/models/segment/types';
import { formatRouteLocation } from '@/utils/format';
import { getPreferredIdentifier } from '@/utils/helpers';

const getRouteTransfers = (segments: OptionModelSequence): SegmentTransferModel[] => {
  return segments.filter((segment) => {
    return 'transferType' in segment
      ? segment.transferType !== TRANSFER_STOP && segment.transferType !== CANAL_TRANSIT
      : false;
  }) as SegmentTransferModel[];
};

export const getSegmentTransferNames = ({
  segments,
  locations,
  codesOnly = false,
}: {
  segments: OptionModelSequence;
  locations: LocationDictionaryModel;
  codesOnly?: boolean;
}): string[] => {
  if (segments.length === 1) return [];

  const transferSegments = getRouteTransfers(segments);
  const transfers = transferSegments.map((segment) => {
    const formPort = formatRouteLocation(segment.fromId, locations);
    const id = formPort ? getPreferredIdentifier(formPort.identifiers) : '';

    if (codesOnly && id) return id.value;
    if (!formPort?.name) return '';
    return `${formPort?.name} ${id ? `(${id.value})` : ''}`;
  });

  return transfers.filter((transfer) => transfer);
};
